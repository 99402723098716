import Container from 'components/Container'
import Button from 'components/Button'
import Icon404 from 'assets/icons/404.svg'
import withContainer from 'hocs/withContainer'
import { GetStaticPropsContext } from 'next'
import serverSideTranslationsWithDefaultNS from 'utils/serverSideTranslationsWithDefaultNS'

export async function getStaticProps({ locale }: GetStaticPropsContext) {
  return {
    props: {
      ...(await serverSideTranslationsWithDefaultNS(locale)),
    },
  }
}

const NotFound = () => {
  return (
    <div className="text-gray-700">
      <div className="max-w-xl mx-auto">
        <Container topAndBottom className="text-center">
          <div className="flex flex-col gap-32 items-center pt-20">
            <div>
              <Icon404 width="320" />
              <div className="text-2xl font-bold">Page Not Found</div>
            </div>
            <Button $fluid href="/">
              Back to Website
            </Button>
          </div>
        </Container>
      </div>
    </div>
  )
}

export default withContainer(NotFound)
